import Icon from '@/components/icon'
import SignInEntryBase from '@/features/marketing/sign-in-activity/activity-entry-base'
import { usePageContext } from '@/hooks/use-page-context'

import styles from './index.module.css'

function SignInHeaderEntry() {
  const { path } = usePageContext()

  const isHomePage = path === '/'

  return (
    <SignInEntryBase introElId="header-sign-in-entry" showRemind={isHomePage} showIntro={isHomePage}>
      {openSignIn => (
        <span id="header-sign-in-entry" className={styles['header-sign-in']}>
          <Icon name="web_user_icon_checkin" fontSize={22} onClick={openSignIn} />
        </span>
      )}
    </SignInEntryBase>
  )
}

export default SignInHeaderEntry
